.custom-navbar {
    background-color: #fff;
    padding: 10px 0;
    border-bottom: 1px solid #f3f3f3;
  }
  
  .custom-navbar .navbar-brand {
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .custom-navbar .nav-link {
    margin-right: 20px;
    font-size: 1.1rem;
  }
  
  .contact-bar {
    background-color: #4285EC;
    color: #fff;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .line-contact {
    margin-left: 1rem;
    background-color: #00c700;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.5rem;
  }
  
  /* .home-content {
    margin-top: 5px;
    padding: 220px 200px;
    background-image: url('../assets/background_home_top_1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
  } */
  
  .home-content2 {
    margin-top: 50px;
    padding: 50px 20px;
    background-image: url('../assets/background_home_2.png');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
  }
  
  .title {
    color: #fff;
  }
  
  .subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .price {
    font-size: 1.2rem;
    color: #1778f2;
    margin-bottom: 20px;
  }
  
  .btn-primary {
    background-color: #1778f2;
    border: none;
  }
  
  .btn-outline-primary {
    border: 1px solid #1778f2;
    color: #1778f2;
  }
  
  .features {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  
  .card-hover {
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .card-hover:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-header {
    background-color: transparent !important;
    border-bottom: none !important;
  }
  
  .card-header-icon {
    color: #1778f2;
    box-shadow: 0 0 2px #302dff;
    border-radius: 2rem;
    border: #81adff 1px solid;
    padding: 0.5rem;
  }
  
  .card-title {
    font-weight: bold;
  }
  
  .card-text {
    text-align: center;
    font-size: 12px;
    color: #555;
  }
  
  .blue-line {
    width: 70px;
    height: 12px;
    background: #1778f2;
    border-radius: 12px;
    margin: 0 auto;
  }
  
  .text-center {
    text-align: center;
  }
  
  .font-weight-bold {
    font-weight: bold;
  }
  
  .fs-4 {
    font-size: 1.5rem;
  }
  
  .text-md-start {
    text-align: left !important;
  }
  
  .text-md-end {
    text-align: right !important;
  }
  
  .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  
  .text-block-with-image .text-block__image {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .login-button {
    background-color: white;
    color: black;
    border-color: black;
    margin-left: 0.5rem;
  }
  
  .trial-button {
    background: linear-gradient(0deg, #0196F2 10.52%, #1778F2 100%);
    color: white;
    border: none;
    margin-left: 0.5rem;
  }

  .home-content2-mobile {
    display: none;
  }
  
  @media (max-width: 768px) {
    .home-content {
      margin-top: -50px;
    }

    .text-moblie {
      font-size: 1rem !important;
    }

    .home-content2 {
      display: none;
    }

    .home-content2-mobile {
      padding: 30px 10px;
      display: block;
    }
  
    .title {
      font-size: 1.5rem;
    }
  
    .subtitle {
      font-size: 1.2rem;
    }
  
    .price {
      font-size: 1rem;
    }
  
    .btn-primary, .btn-outline-primary {
      width: 100%;
      margin-top: 10px;
    }
  
    .features .card-hover {
      margin-bottom: 10px;
    }
  
    .text-md-start, .text-md-end {
      text-align: center !important;
    }

    .text-md-end {
        background-color: #f8f8f8 !important;
        border-radius: 1rem;
    }
  }  
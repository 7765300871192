.text-muted {
  color: #6c757d !important;
}

.text-success {
  color: #4285EC !important;
}

.card-custom {
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.card-custom:hover {
  transform: scale(1.05);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.card-title-custom {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-subtitle-custom {
  font-size: 1.25rem;
}

.card-text-custom {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.border-success-custom {
  border: 2px solid #4285EC !important;
}

.mb-4-custom {
  margin-bottom: 1.5rem !important;
}

.recommended-label {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff4c4c;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
}
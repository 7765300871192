.footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    border-top: 1px solid #ddd;
  }
  
  .footer h5 {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  .footer ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .footer ul li {
    margin-bottom: 10px;
  }
  
  .footer ul li a {
    color: #343a40;
    text-decoration: none;
  }
  
  .footer ul li a:hover {
    text-decoration: underline;
  }
  
  .footer .text-center {
    margin-top: 20px;
    font-size: 0.9rem;
  }
  
.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #007bff;
    border-radius: 10px;
    background-color: #f1f9ff;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .login-form .form-group {
    margin-bottom: 15px;
  }
  
  .login-form .form-label {
    color: #007bff;
  }
  
  .login-form .form-control {
    border-radius: 5px;
    border: 1px solid #007bff;
  }
  
  .login-button2 {
    width: 100%;
    background-color: #007bff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
  }
  
  .login-button2:hover {
    background-color: #0056b3;
  }
  